import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/utbot-site-staging/utbot-site-staging/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "сonfigure-test-generation-according-to-your-testing-purposes"
    }}>{`Сonfigure test generation according to your testing purposes`}</h2>
    <h3 {...{
      "id": "mocking-settings"
    }}>{`Mocking settings`}</h3>
    <ul>
      <li parentName="ul">{`Are your units `}<a parentName="li" {...{
          "href": "https://martinfowler.com/bliki/UnitTest.html"
        }}>{`solitary or sociable`}</a>{`? Do you need mocking? What kind of
mocking if necessary?`}<br></br>{`
UnitTestBot uses `}<a parentName="li" {...{
          "href": "https://site.mockito.org/"
        }}>{`Mockito`}</a>{` framework to control dependencies in
your code during testing. Choose what you need or rely on the defaults.`}</li>
    </ul>
    <p>{`Note: currently, mocking is not available if you allocate all the test generation time for `}<strong parentName="p">{`Fuzzing`}</strong>{` (see `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Fine-tune-test-generation#test-generation-method"
      }}><strong parentName="a">{`Test generation method`}</strong></a>{`).`}</p>
    <hr></hr>
    <h4 {...{
      "id": "mocking-strategy"
    }}>{`Mocking strategy`}</h4>
    <p>{`In the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window choose your `}<strong parentName="p">{`Mocking strategy`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Do not mock`}</strong>{` — If you want the unit under test to actually interact with its environment, mock nothing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mock everything outside the package`}</strong>{` — Mock everything around the current package except the system classes.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mock everything outside the class`}</strong>{` — Mock everything around the target class except the system classes.`}</li>
    </ul>
    <p><em parentName="p">{`Notes:`}</em></p>
    <p>{`This setting is inactive when you enable `}<strong parentName="p">{`Parameterized tests`}</strong>{`.`}</p>
    <p>{`Please remember, that `}<strong parentName="p">{`Classes to be forcedly mocked`}</strong>{` are mocked even if you choose to mock nothing or any other
mocking option. This behavior is necessary for correct test generation. See `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Fine-tune-test-generation#force-mocking-static-methods"
      }}><strong parentName="a">{`Force mocking static methods`}</strong></a>{` for more information.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "mock-static-methods"
    }}>{`Mock static methods`}</h4>
    <p>{`In the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window you can choose to `}<strong parentName="p">{`Mock static methods`}</strong>{`.`}</p>
    <p>{`This option became available with Mockito 3.4.0. You can mock static methods only if you choose to mock either package or class environment. If you mock nothing, you won't be able to mock static methods as well, except those which are forcedly mocked.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "force-mocking-static-methods"
    }}>{`Force mocking static methods`}</h4>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and choose to `}<strong parentName="p">{`Force mocking static methods`}</strong>{`.`}</p>
    <p>{`When enabled, it overrides all the other mocking settings.`}</p>
    <p>{`It will mock the methods inside the `}<strong parentName="p">{`Classes to be forcedly mocked`}</strong>{` even if the Mockito is not installed.`}</p>
    <p>{`Keep this setting enabled until you are a contributor and want to experiment with UnitTestBot code.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "classes-to-be-forcedly-mocked"
    }}>{`Classes to be forcedly mocked`}</h4>
    <p>{`If you chose to `}<strong parentName="p">{`Force mocking static methods`}</strong>{`, you'll see four classes among `}<strong parentName="p">{`Classes to be forcedly mocked`}</strong>{`, which are always here:`}</p>
    <p><inlineCode parentName="p">{`java.util.Random`}</inlineCode>{`
`}<inlineCode parentName="p">{`org.slf4j.Logger`}</inlineCode>{`
`}<inlineCode parentName="p">{`org.slf4j.LoggerFactory`}</inlineCode>{`
`}<inlineCode parentName="p">{`org.utbot.api.mock.UtMock`}</inlineCode></p>
    <p>{`They must be mocked for correct test generation.`}</p>
    <p>{`You can add to this list. What should be forcedly mocked?`}</p>
    <p>{`Even if you choose to mock nothing or mock the package environment, please make sure to forcedly mock `}<em parentName="p">{`random number generators`}</em>{`, `}<em parentName="p">{`I/O operation`}</em>{`s and `}<em parentName="p">{`loggers`}</em>{` to get valid test results.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Do you need to execute a single test method multiple times with different parameters?`}</li>
    </ul>
    <h3 {...{
      "id": "parameterized-tests"
    }}>{`Parameterized tests`}</h3>
    <p>{`You can enable Parameterized tests in the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window.`}</p>
    <p>{`In UnitTestBot parameterized tests are available only for JUnit 5 and TestNG chosen as the testing framework. UnitTestBot does not support parameterization for JUnit 4.`}</p>
    <p>{`If this setting is enabled, you cannot choose `}<strong parentName="p">{`Mocking strategy`}</strong>{`. `}<strong parentName="p">{`Do not mock`}</strong>{` will be set as default.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Would you like to collect and run tests using JUnit 4, JUnit 5 or TestNG?`}</li>
    </ul>
    <h3 {...{
      "id": "testing-framework"
    }}>{`Testing framework`}</h3>
    <p>{`In the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window choose the necessary `}<strong parentName="p">{`Testing framework`}</strong>{`: `}<strong parentName="p">{`JUnit 4`}</strong>{`, `}<strong parentName="p">{`JUnit 5`}</strong>{` or `}<strong parentName="p">{`TestNG`}</strong>{`.`}</p>
    <p>{`If you do not have some of them on your computer, UnitTestBot will install them for you.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` if you choose `}<strong parentName="p">{`JUnit 4,`}</strong>{` you won't be able to activate `}<strong parentName="p">{`Parameterized tests`}</strong>{`.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Do you need to generate test methods in Kotlin for the source code written in Java or vice versa?`}</li>
    </ul>
    <h3 {...{
      "id": "generated-test-language"
    }}>{`Generated test language`}</h3>
    <p>{`By default, UnitTestBot detects your source code language and generates test methods accordingly.`}</p>
    <p>{`In `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` you can choose `}<strong parentName="p">{`Generated test language`}</strong>{` to generate test methods in Java or Kotlin regardless of your source code language.`}</p>
    <p>{`Generating tests in Kotlin is an experimental feature for now.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Choose a folder to store your test code.`}</li>
    </ul>
    <h3 {...{
      "id": "test-sources-root"
    }}>{`Test sources root`}</h3>
    <p>{`For your first test generation with UnitTestBot choose the Test sources root manually in the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window. UnitTestBot will remember your choice for future generations.`}</p>
    <p>{`If you `}<em parentName="p">{`re-generate`}</em>{` the test for a given class or method, it will `}<em parentName="p">{`erase the previously generated test`}</em>{` for this unit. To prevent this, rename or move the tests you want to keep.`}</p>
    <p>{`If the `}<strong parentName="p">{`Test sources root`}</strong>{` directory `}<em parentName="p">{`already contains hand-written tests`}</em>{` or tests created with IntelliJ IDEA `}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/help/idea/create-tests.html"
      }}>{`Create Test`}</a>{` feature, make sure to rename or move these tests. UnitTestBot will `}<em parentName="p">{`overwrite`}</em>{` them, if they have the same names as the generated ones.`}</p>
    <p>{`If your project is built with Maven or Gradle, you will be able to choose only the pre-defined `}<strong parentName="p">{`Test sources root`}</strong>{` options from the drop-down list due to a strict project directory layout. If necessary, please `}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/help/idea/testing.html#add-test-root"
      }}>{`define custom `}<strong parentName="a">{`Test sources root`}</strong>{` via Gradle or Maven`}</a>{` by modifying the build file. If you use IntelliJ IDEA native build system you can mark any directory as `}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/help/idea/testing.html#add-test-root"
      }}>{`Test Sources Root`}</a>{`.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Choose methods, classes or packages you want to cover with tests.`}</li>
    </ul>
    <h3 {...{
      "id": "generate-tests-for"
    }}>{`Generate tests for`}</h3>
    <p>{`You can choose the scope for test generation both in the Project tool window or in the Editor.`}</p>
    <ul>
      <li parentName="ul">{`If you need to `}<em parentName="li">{`generate tests for a package or a set of classes`}</em>{`, you can choose them all at once in the Project tool window.`}</li>
      <li parentName="ul">{`If you want to `}<em parentName="li">{`specify methods`}</em>{` to be covered with tests inside the given class, you can choose the class in the
Project tool window or, alternatively, place the caret at the required class or method right in the Editor.`}<br></br>{`
Then in the `}<strong parentName="li">{`Generate Tests with UnitTestBot`}</strong>{` window tick the classes or methods you'd like to cover with tests.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "experiment-with-test-generation-to-reach-higher-coverage"
    }}>{`Experiment with test generation to reach higher coverage`}</h2>
    <p>{`There are ways to configure the inner process of test generation. They are mostly intended for contributors' use, but sometimes they may be helpful for common usage. Try them if you are not satisfied with test coverage. Please make sure you've `}<em parentName="p">{`set everything back to defaults`}</em>{` after finishing your experiments! Otherwise, you may have poor results during the next standard test generation.`}</p>
    <p>{`Before configuring these settings, get to know the shortest UnitTestBot overview ever:`}</p>
    <p><em parentName="p">{`UnitTestBot has a `}<a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Symbolic_execution"
        }}>{`dynamic symbolic execution`}</a>{` engine in its core, complemented with a smart `}<a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Fuzzing"
        }}>{`fuzzing`}</a>{` technique. Fuzzer tries to "guess" values, which allow UnitTestBot to enter as much branches as possible. Dynamic symbolic engine in its turn tries to "deduce" the same. UnitTestBot starts its work with a little bit of fuzzing, quickly generating inputs to cover some branches. Then the dynamic symbolic execution engine systematically explores the program's execution paths. It simultaneously executes the program on both the `}<a parentName="em" {...{
          "href": "https://www.youtube.com/watch?v=QrtGOrSrVPQ&t=1008s"
        }}>{`concrete and the symbolic values`}</a>{`. These two ways of execution guide each other, trying to reach all the possible branches.`}</em></p>
    <hr></hr>
    <h3 {...{
      "id": "hanging-test-timeout-milliseconds-per-method"
    }}>{`Hanging test timeout (milliseconds per method)`}</h3>
    <p>{`The symbolic engine generates parameters for the concrete execution — executing source code with concrete values. During concrete execution, if the engine enters an infinite loop or meet some other code conditions which take too much time to execute, the test being generated "hangs". It also means that the resulting test will hang when running and invoking the time-taking method.`}</p>
    <p>{`Hanging test timeout is the limitation for the concrete execution process. Set this timeout to define which test is
"hanging". Increase it to test the time-consuming method or decrease if the execution speed is critical for you.`}</p>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and set timeout in milliseconds — at 50 milliseconds intervals.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "test-generation-timeout-seconds-per-class"
    }}>{`Test generation timeout (seconds per class)`}</h3>
    <p>{`In the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window you can set the timeout for `}<em parentName="p">{`both the fuzzing process and the symbolic engine`}</em>{` to generate tests for a given class.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "test-generation-method"
    }}>{`Test generation method`}</h3>
    <p>{`UnitTestBot starts its work with `}<strong parentName="p">{`Fuzzing`}</strong>{`, switching to `}<strong parentName="p">{`Symbolic execution`}</strong>{` later.`}</p>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and choose the proportion of time allocated for each of these two methods within `}<strong parentName="p">{`Test generation timeout`}</strong>{` per class.`}</p>
    <p>{`The closer the slider is to each method (`}<strong parentName="p">{`Fuzzing`}</strong>{` or `}<strong parentName="p">{`Symbolic execution`}</strong>{`) the more time you allocate for it.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "configure-test-behavior-and-appearance"
    }}>{`Configure test behavior and appearance`}</h2>
    <p>{`You can adjust the way the generated tests behave and look like.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "tests-with-exceptions"
    }}>{`Tests with exceptions`}</h3>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and choose the behavior for the tests with exceptions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{` — tests that produce Runtime exceptions should fail.`}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{` — tests that produce Runtime exceptions should pass (by inserting throwable assertion).`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "overflow-detection"
    }}>{`Overflow detection`}</h3>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and choose the approach to overflows: whether UnitTestBot should `}<strong parentName="p">{`Ignore overflows`}</strong>{` or `}<strong parentName="p">{`Treat overflows`}</strong>{` as errors.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "javadoc-comment-style"
    }}>{`Javadoc comment style`}</h3>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Tools`}</strong>{` > `}<strong parentName="p">{`UnitTestBot`}</strong>{` and choose the style for test descriptions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Structured via custom Javadoc tags`}</strong>{` — UnitTestBot uses custom Javadoc tags to concisely describe test execution path.`}</li>
      <li parentName="ul"><strong parentName="li">{`Plain text`}</strong>{` — UnitTestBot briefly describes test execution path in plain text.`}</li>
    </ul>
    <p>{`For more information and illustrations see Read test descriptions.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` currently, the feature works only for symbolic execution engine, so make sure you've allocated all the time to `}<strong parentName="p">{`Symbolic execution`}</strong>{` in `}<strong parentName="p">{`Test generation method`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      